import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Route, Routes } from "react-router-dom";
  

import Navbar from "./Navbar";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navbar />}></Route>
      </Routes>
    </>
  );
}

export default App;
